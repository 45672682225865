import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Clôturer mon compte bancaire - Ahh, mais comment faire ?</title>
                <meta name="description" content="
              Avec autant d'offres bancaires disponibles, c'est le moment de changer de banque. Mais avant de changer, il faut clôturer mon compte existant. A l'aide !" />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/academie/`} style={{color: 'black'}}>Banque Académie</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Clôturer son compte bancaire – comment faire ?</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Clôturer son compte bancaire – comment faire ?</Title>
			<StaticImage
		  	src='../../images/cloturer-un-compte-bancaire.jpeg'
			width={1200}
			alt='Clôturer un compte bancaire'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
			<p>Aujourd’hui, clôturer un compte bancaire est devenu plus simple grâce à l’intervention des pouvoirs publics et les multiples mesures relatives à la mobilité bancaire. Cela va de sens dans un contexte où les frais bancaires sont en constante augmentation et où la concurrence entre les banques s’est renforcée, plus particulièrement avec l’avènement des banques en ligne ou encore des néo-banques.
			</p>
<h2>Vérifier qu’aucune opération ne sera effectuée</h2>
<p>Avant de procéder à la clôture de son compte bancaire, il convient avant tout de vérifier qu’aucun virement ou prélèvement ne sera effectué. De même, si des paiements ont été réalisés par chèque ou par carte bancaire, le client se doit de laisser le solde nécessaire pour éviter tout incident de paiement. Après la clôture, l’ancien établissement se doit de prévenir gratuitement le particulier si un chèque se présente dans les 13 mois qui suivent.
</p>
<h2>Demander la clôture du compte</h2>
<p>Pour signifier son intention de clôturer le compte, le titulaire devra envoyer une lettre en recommandé avec accusé de réception à la banque concernée. Le délai de préavis est précisé dans la convention de compte signée lors de l’ouverture et sera donc appliqué avant que la fermeture devienne effective. Le bénéficiaire se doit de restituer les moyens de paiement qui étaient mis à sa disposition et régulariser sa situation si le compte était à découvert.
</p><p>
Après comparaison des banques, si le particulier a ouvert un compte au sein d’un autre établissement, il peut demander à ce dernier de piloter la fermeture grâce au dispositif d’aide à la mobilité bancaire.
</p>
<h2>Clôturer un compte joint</h2>
<p>Dans le cas d’une clôture de compte joint, la demande se fait également via un courrier de résiliation envoyé à la banque. La signature des cotitulaires est obligatoire pour la prise en compte de la requête. Si le solde du compte est négatif, l’établissement peut demander la dette à chacun des cotitulaires.
</p>
<h2>L’importance de la fermeture</h2>
<p>Il pourrait être tentant de se passer de cette démarche en pensant que de toute façon aucun frais ne sera engendré si le compte ne fonctionne plus. Erreur.
</p><p>
À savoir, un compte est considéré comme inactif lorsque aucune opération n’a été effectuée sur le compte par le titulaire ou son représentant légal durant 12 mois et qu’aucun d’eux ne s’est manifesté auprès de la banque et que les frais de tenue de compte inactif peuvent s’élever jusqu’à 30 € par an.
</p>	</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
